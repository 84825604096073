import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Cómo jugar" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Adiviná la palabra en 6 intentos. Después de cada intento, el color de
        las teclas se modificará para mostrarte qué tan cerca estás de acertar.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="A" status="correct" />
        <Cell value="F" />
        <Cell value="O" />
        <Cell value="R" />
        <Cell value="O" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La palabra contiene la letra A y está en el lugar correcto.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="D" />
        <Cell value="O" />
        <Cell value="G" status="present" />
        <Cell value="A" />
        <Cell value="L" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La palabra contiene la letra G pero está en el lugar incorrecto.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="N" />
        <Cell value="A" />
        <Cell value="N" />
        <Cell value="A" />
        <Cell value="I" status="absent" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        La palabra no contiene la letra I.
      </p>
    </BaseModal>
  )
}
