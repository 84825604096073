type Definition = {
  word: string
  displayWord: string
  definition: string
  example: string
}
const DEFINITIONS: Definition[] = [
  {
    word: 'rucci',
    displayWord: 'Rucci',
    definition:
      '“El instrumento más relevante [de Clics Modernos], para mí, es la máquina...',
    example:
      'https://www.diezpalabras.com.ar/numero-59-la-indomita-luz/1548/3-rucci/',
  },
  {
    word: 'zarpe',
    displayWord: 'Zarpe',
    definition:
      'Pero qué afortunades fuimos. Me voy a permitir ponerle espacios a estas...',
    example:
      'https://www.diezpalabras.com.ar/d10spalabras-numero-14-dolor-y-gloria/219/10-zarpe/',
  },
  {
    word: 'forty',
    displayWord: 'Forty',
    definition:
      '"Hey guys, wanna feel old? I\'m 40. You\'re welcome." Al diabólico Macaulay...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-cero/86/9-forty/',
  },
  {
    word: 'enero',
    displayWord: 'Enero',
    definition:
      'Me gusta enero, el mes y la palabra. Y Río de Janeiro, y la frase "me...',
    example:
      'https://www.diezpalabras.com.ar/numero-68-una-tregua-con-el-ancho-mundo/1798/10-enero/',
  },
  {
    word: 'fogon',
    displayWord: 'Fogón',
    definition:
      'Recién hoy noté que un fogón no es más (no es menos) que un fuego grande....',
    example: 'https://www.diezpalabras.com.ar/numero-70-un-fogon/1833/9-fogon/',
  },
  {
    word: 'pedir',
    displayWord: 'Pedir',
    definition:
      'Habló el papa Francisco. “Me vuelvo pedigüeño y paso a pedir.(...)...',
    example:
      'https://www.diezpalabras.com.ar/numero-58-algun-tipo-de-caos/1530/5-pedir/',
  },
  {
    word: 'aforo',
    displayWord: 'Aforo',
    definition:
      'Pregunta Diego Lerer desde cuándo decimos “aforo” en Argentina. Mariana...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-24-tan-lejos-tan-cerca/318/8-aforo/',
  },
  {
    word: 'qanon',
    displayWord: 'QAnon',
    definition:
      'No cualquier teoría conspirativa asalta el Capitolio de Estados Unidos....',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-19-medi-tus-palabras/263/3-qanon/',
  },
  {
    word: 'diego',
    displayWord: 'Diego',
    definition:
      'El nombre propio de este año. Hay tantos Diegos, y hay uno solo. Diego...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-18-especial-fin-de-ano/258/8-diego/',
  },
  {
    word: 'nanas',
    displayWord: 'Nanas',
    definition:
      '“Medio color que se dan los cuicos con el nombramiento de Luz Vidal....',
    example:
      'https://www.diezpalabras.com.ar/numero-71-uno-necesita-el-mar-para-esto/1851/9-nanas/',
  },
  {
    word: 'gluck',
    displayWord: 'Glück',
    definition:
      'La flamante Nobel de Literatura 2020 se llama Louise Glück; si tradujéramos...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-6-bajo-influencia/147/10-gluck/',
  },
  {
    word: 'salud',
    displayWord: 'Salud',
    definition:
      'El año en que los lugares comunes revalidaron sus títulos. Frases gastadas...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-18-especial-fin-de-ano/252/2-salud/',
  },
  {
    word: 'moria',
    displayWord: 'Moria',
    definition:
      'También hubo incendios en el campo de refugiados de Moria, el más grande...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-dos-de-crater-a-emoji/101/4-moria/',
  },
  {
    word: 'ayuno',
    displayWord: 'Ayuno',
    definition:
      '“El ayuno, en estos tiempos, debería ser digital?” pregunta Santiago...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-31-carne-de-canon-digital/385/3-ayuno/',
  },
  {
    word: 'nexta',
    displayWord: 'Nexta',
    definition:
      'Una de espías: el domingo, Lukashenko, el presidente de Bielorrusia (donde...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-39-50-sombras-mas-oscuras-y-algo-de-luz/469/7-nexta/',
  },
  {
    word: 'likes',
    displayWord: 'Likes',
    definition:
      'Del pozo al escenario de la sociedad. “¿Cuántos likes en Twitter para...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-6-bajo-influencia/144/7-likes/',
  },
  {
    word: 'marte',
    displayWord: 'Marte',
    definition:
      '“Hoy aterriza Perseverance, la nave del nombre épico, en Marte. Palpita...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-25-mundo-para-quien/330/10-marte/',
  },
  {
    word: 'wayuu',
    displayWord: 'Wayuu',
    definition:
      'Solo una de las tantas historias de comunidades afectadas por el cambio...',
    example:
      'https://www.diezpalabras.com.ar/numero-49-donde-esta-la-diversion-perdida/1224/5-wayuu/',
  },
  {
    word: 'angau',
    displayWord: 'Angaú',
    definition:
      '“linkedin puede ser un lugar muy interesante si seguís lo que realmente...',
    example:
      'https://www.diezpalabras.com.ar/numero-52-lexico-extraterrestre/1402/10-angau/',
  },
  {
    word: 'dakou',
    displayWord: 'Dakou',
    definition:
      '“Hacia principios de los noventa, China comenzó a importar toneladas...',
    example:
      'https://www.diezpalabras.com.ar/numero-81-la-aurora-del-mar/2155/6-dakou/',
  },
  {
    word: 'yinmn',
    displayWord: 'YInMn',
    definition:
      'YInMn es un nuevo azul (y no es el de los calzones de Navalny). Lo venden...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-23-las-guerras-del-agua/310/10-yinmn/',
  },
  {
    word: 'Ñeris',
    displayWord: 'Ñeris',
    definition:
      'Hay palabras que vienen a decirme cuántos mundos existen sin mí (spoiler:...',
    example:
      'https://www.diezpalabras.com.ar/numero-72-cosas-que-parecen/1862/2-neris/',
  },
  {
    word: 'clara',
    displayWord: 'Clara',
    definition:
      'Mi abuela Sara era hija única. Nació después de la guerra (y antes de...',
    example:
      'https://www.diezpalabras.com.ar/numero-80-seamos-mas-grandes/2139/9-clara/',
  },
  {
    word: 'higui',
    displayWord: 'Higui',
    definition:
      '“Hace días una chica fue violada grupalmente en Palermo. No pudo defenderse....',
    example:
      'https://www.diezpalabras.com.ar/numero-75-demasiadas-cosas-raras/1928/7-higui/',
  },
  {
    word: 'china',
    displayWord: 'China',
    definition:
      '“El uso campero de ‘china’ como sinónimo de mujer proviene del quechua....',
    example:
      'https://www.diezpalabras.com.ar/numero-58-algun-tipo-de-caos/1528/3-china/',
  },
  {
    word: 'arbol',
    displayWord: 'Árbol',
    definition:
      'Con la asunción de Gabriel Boric como presidente de Chile, las redes se...',
    example:
      'https://www.diezpalabras.com.ar/numero-76-vamos-lento-porque-vamos-lejos/1960/9-arbol/',
  },
  {
    word: 'robot',
    displayWord: 'Robot',
    definition:
      '“La palabra ‘robot’ viene del término checo ‘robota’, que significa...',
    example:
      'https://www.diezpalabras.com.ar/numero-83-complacer-al-algoritmo/2212/5-robot/',
  },
  {
    word: 'murio',
    displayWord: 'Murió',
    definition: '',
    example:
      'https://www.diezpalabras.com.ar/d10spalabras-numero-14-dolor-y-gloria/210/1-murio/',
  },
  {
    word: 'turba',
    displayWord: 'Turba',
    definition:
      'Suelo que se hunde bajo mis pies; que se hunde pero no tanto. Apenas. O...',
    example: 'https://www.diezpalabras.com.ar/numero-70-un-fogon/1832/8-turba/',
  },
  {
    word: 'mtoto',
    displayWord: 'Mtoto',
    definition:
      'Un equipo científico europeo ha encontrado en la cueva de Panga ya Saidi,...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-37-la-estrella-de-fuego/452/10-mtoto/',
  },
  {
    word: 'gusto',
    displayWord: 'Gusto',
    definition:
      'Qué alegría la alegría que no se ve venir y de pronto, acontece. A mí...',
    example:
      'https://www.diezpalabras.com.ar/numero-62-lo-inesperado-tambien-acontece/1633/10-gusto/',
  },
  {
    word: 'craso',
    displayWord: 'Craso',
    definition:
      '“Oh Elon, como hombre más rico de nuestro tiempo, ojalá compartas el...',
    example:
      'https://www.diezpalabras.com.ar/numero-80-seamos-mas-grandes/2137/7-craso/',
  },
  {
    word: 'swift',
    displayWord: 'SWIFT',
    definition:
      '“Último momento: La Unión Europea, Francia, Alemania, Italia, Canadá...',
    example:
      'https://www.diezpalabras.com.ar/numero-74-el-compost-del-tiempo/1903/7-swift/',
  },
  {
    word: 'uigur',
    displayWord: 'Uigur',
    definition:
      'El secretario de Estado de Estados Unidos, Antony Blinken, destacó el...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-31-carne-de-canon-digital/387/5-uigur/',
  },
  {
    word: 'bomba',
    displayWord: 'Bomba',
    definition:
      'Hay desastres naturales y hay desastres humanos y hay lo que llaman “un...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-21-surfeando-el-apocalipsis/283/3-bomba/',
  },
  {
    word: 'vivir',
    displayWord: 'Vivir',
    definition:
      'Circula un clip de 1992, una entrevista de un joven Antonio Birabent. -Charly,...',
    example:
      'https://www.diezpalabras.com.ar/numero-59-la-indomita-luz/1555/10-vivir/',
  },
  {
    word: 'cruel',
    displayWord: 'Cruel',
    definition:
      '"Yo ejercí violencia obstétrica sobre mujeres que tenían la mala suerte...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-17-rojo-y-verde/241/1-cruel/',
  },
  {
    word: 'playa',
    displayWord: 'Playa',
    definition:
      'Una playa es playa como un plato playo. Me acordé de un texto de Evelin...',
    example:
      'https://www.diezpalabras.com.ar/numero-71-uno-necesita-el-mar-para-esto/1846/4-playa/',
  },
  {
    word: 'libre',
    displayWord: 'Libre',
    definition:
      'Gabriel Boric Font cierra su discurso de asunción desde el balcón del...',
    example:
      'https://www.diezpalabras.com.ar/numero-76-vamos-lento-porque-vamos-lejos/1961/10-libre/',
  },
  {
    word: 'iraci',
    displayWord: 'Irací',
    definition:
      '“La economista chilena Irací Hassler Jacob (Santiago de Chile, de 30...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-38-extratropicalisima/460/8-iraci/',
  },
  {
    word: 'dogal',
    displayWord: 'Dogal',
    definition:
      'Qué pavada discutir las figuras negras en los envases, ¿no? Leo que el...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-39-50-sombras-mas-oscuras-y-algo-de-luz/468/6-dogal/',
  },
  {
    word: 'tarep',
    displayWord: 'TAREP',
    definition:
      'Se está discutiendo en el Congreso un proyecto de ley de Envases, con...',
    example:
      'https://www.diezpalabras.com.ar/numero-62-lo-inesperado-tambien-acontece/1629/6-tarep/',
  },
  {
    word: 'dosis',
    displayWord: 'Dosis',
    definition:
      'Esta tampoco salió así, a secas. Se ve que empezamos a hablar de dosis...',
    example:
      'https://www.diezpalabras.com.ar/numero-67-las-diez-palabras-de-2021/1738/7-dosis/',
  },
  {
    word: 'covax',
    displayWord: 'COVAX',
    definition:
      'Salimos colaborando, claro. Es irónico que la palabra coronavirus empiece...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-uno/91/4-covax/',
  },
  {
    word: 'hasel',
    displayWord: 'Hasél',
    definition:
      'España arde: van cinco noches de protesta tras la detención de Pablo...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-25-mundo-para-quien/327/7-hasel/',
  },
  {
    word: 'duelo',
    displayWord: 'Duelo',
    definition:
      'El miércoles pasamos las cien mil muertes por COVID-19 en Argentina. No...',
    example:
      'https://www.diezpalabras.com.ar/numero-45-futuro-para-que/653/2-duelo/',
  },
  {
    word: 'seiko',
    displayWord: 'Seiko',
    definition:
      'Las palabras pesan, acá y en Japón. “Creo que los Juegos Olímpicos...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-25-mundo-para-quien/328/8-seiko/',
  },
  {
    word: 'theia',
    displayWord: 'Theia',
    definition:
      'Lo que tiene el barro es posibilidades. Ahora dicen (Nature dice) que hay...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-31-carne-de-canon-digital/392/10-theia/',
  },
  {
    word: 'aukus',
    displayWord: 'Aukus',
    definition:
      'El miércoles, Estados Unidos, Australia y el Reino Unido anunciaron un...',
    example:
      'https://www.diezpalabras.com.ar/numero-54-viniste-a-mi-tomaste-de-mi-copa/1458/6-aukus/',
  },
  {
    word: 'errar',
    displayWord: 'Errar',
    definition:
      'En La Sociedad Errante, Keri Smith cita la definición de diccionario de...',
    example:
      'https://www.diezpalabras.com.ar/numero-68-una-tregua-con-el-ancho-mundo/1790/2-errar/',
  },
  {
    word: 'equis',
    displayWord: 'Equis',
    definition:
      '“Es la intersección donde se encuentran todas las personas oprimidas”,...',
    example:
      'https://www.diezpalabras.com.ar/numero-48-imaginarnos-ese-cielo-alienigena/724/2-equis/',
  },
  {
    word: 'noche',
    displayWord: 'Noche',
    definition:
      '—Bueno —dijo el padre con voz muy suave—. A lo nuestro. —¿Puedo...',
    example:
      'https://www.diezpalabras.com.ar/numero-42-tenemos-toda-la-noche/615/10-noche/',
  },
  {
    word: 'vesak',
    displayWord: 'Vesak',
    definition:
      '“¡Feliz Vesak a todes mis amigues budistas!”, tuiteó el miércoles...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-39-50-sombras-mas-oscuras-y-algo-de-luz/472/10-vesak/',
  },
  {
    word: 'corfo',
    displayWord: 'Corfo',
    definition:
      '“La polución vuelve rosa un lago en Argentina”, titulaba el lunes...',
    example:
      'https://www.diezpalabras.com.ar/numero-47-o-como-quieran-llamar-a-ese-golpe-que-corta/705/1-corfo/',
  },
  {
    word: 'dueña',
    displayWord: 'Dueña',
    definition:
      'Otra de sucesiones, familias y reivindicaciones. Un aplauso para le guionista....',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-9-regenerar/161/3-duena/',
  },
  {
    word: 'union',
    displayWord: 'Unión',
    definition:
      '“Queremos agradecerle a Jeff Bezos por irse al espacio, porque mientras...',
    example:
      'https://www.diezpalabras.com.ar/numero-78-rio-luna-y-libertad/2098/6-union/',
  },
  {
    word: 'yamal',
    displayWord: 'Yamal',
    definition:
      '“El principal gasoducto ruso hacia Europa interrumpe el suministro”,...',
    example:
      'https://www.diezpalabras.com.ar/numero-75-demasiadas-cosas-raras/1924/3-yamal/',
  },
  {
    word: 'bucha',
    displayWord: 'Bucha',
    definition:
      '¿Será Bucha uno de esos lugares que quedan marcados para siempre por...',
    example:
      'https://www.diezpalabras.com.ar/numero-79-el-zumbido-de-la-posibilidad/2117/6-bucha/',
  },
  {
    word: 'palto',
    displayWord: 'Palto',
    definition:
      'Chile es el único país en el mundo donde la constitución vigente -aprobada...',
    example:
      'https://www.diezpalabras.com.ar/numero-81-la-aurora-del-mar/2151/2-palto/',
  },
  {
    word: 'nanai',
    displayWord: 'Nanai',
    definition:
      'Más de la entrevista de BBC a Boric. “-Da la sensación que para usted...',
    example:
      'https://www.diezpalabras.com.ar/numero-69-se-mueve-se-mueve/1816/10-nanai/',
  },
  {
    word: 'emoji',
    displayWord: 'Emoji',
    definition:
      'Una cosa es dirigir el juego y otra es elegir las piezas. "Como Gobernador...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-dos-de-crater-a-emoji/107/10-emoji/',
  },
  {
    word: 'rosca',
    displayWord: 'Rosca',
    definition:
      '“Mirá, yo tengo una empresa que se llama Bridges, es una consultora...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-17-rojo-y-verde/246/6-rosca/',
  },
  {
    word: 'esmad',
    displayWord: 'ESMAD',
    definition:
      'Colombia sigue en llamas; ya van once días de protestas. Como pasó en...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-36-el-relampagueante-brillo-de-la-nada/438/6-esmad/',
  },
  {
    word: 'rilei',
    displayWord: 'Rilei',
    definition:
      'Tuiteó Alejandro Kaufman el domingo: “En el empeño del dúo Rilei de...',
    example:
      'https://www.diezpalabras.com.ar/numero-76-vamos-lento-porque-vamos-lejos/1958/7-rilei/',
  },
  {
    word: 'delta',
    displayWord: 'Delta',
    definition:
      'No solo las personas cambian sus nombres asignados al nacer: las cepas...',
    example:
      'https://www.diezpalabras.com.ar/numero-40-les-quatre-cents-mots/556/3-delta/',
  },
  {
    word: 'excel',
    displayWord: 'Excel',
    definition:
      '¿Te acordás que había una pandemia? En el Reino Unido tuvieron un temita...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-6-bajo-influencia/141/4-excel/',
  },
  {
    word: 'negra',
    displayWord: 'Negra',
    definition:
      '¿Quién puede usar la palabra “negra”? Esta semana también tuvimos...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-27-racializacion-y-barbarie/345/3-negra/',
  },
  {
    word: 'aleph',
    displayWord: 'Aleph',
    definition:
      'La nubada mañana de noviembre en que Diego Maradona murió, después de...',
    example:
      'https://www.diezpalabras.com.ar/d10spalabras-numero-14-dolor-y-gloria/215/6-aleph/',
  },
  {
    word: 'ganar',
    displayWord: 'Ganar',
    definition:
      '“Las últimas guerras –Libia, Afganistán, Irak– han supuesto el...',
    example:
      'https://www.diezpalabras.com.ar/numero-79-el-zumbido-de-la-posibilidad/2118/7-ganar/',
  },
  {
    word: 'hakim',
    displayWord: 'Hakim',
    definition:
      'Alejandro Ariel Hakim ayudaba a nacer: era el jefe de obstetricia del Hospital...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-15-de-bits-y-atomos/228/8-hakim/',
  },
  {
    word: 'nftdp',
    displayWord: 'NFTDP',
    definition:
      '“Ha llegado el NFT definitivo”, tuiteó Paloma Llaneza el jueves. “Amigas,...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-31-carne-de-canon-digital/383/1-nftdp/',
  },
  {
    word: 'hapax',
    displayWord: 'Hápax',
    definition:
      '“Yo iba a decir ‘hápax’”, venía diciendo mientras tanto Santiago...',
    example:
      'https://www.diezpalabras.com.ar/numero-76-vamos-lento-porque-vamos-lejos/1955/4-hapax/',
  },
  {
    word: 'datos',
    displayWord: 'Datos',
    definition:
      '"Los datos muestran que los contagios no suceden en el colegio", dijo anoche...',
    example:
      'https://www.diezpalabras.com.ar/diezpalabras-numero-34-asi-en-la-tierra-como-en-el-cielo-y-otro-alla/417/5-datos/',
  },
  {
    word: 'chivo',
    displayWord: 'Chivo',
    definition:
      '“Ya está disponible @chivowallet en @GooglePlay, para los 156 modelos...',
    example:
      'https://www.diezpalabras.com.ar/numero-53-el-poder-de-un-nudo-bien-hecho/1436/2-chivo/',
  },
]

let WORDS: string[] = []

DEFINITIONS.forEach(function (item, index) {
  WORDS.push(item.word)
})

export { WORDS, DEFINITIONS }
