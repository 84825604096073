import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const AboutModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Sobre Diezple" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Este es un juego argentino basado en el código open source de{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wordle
        </a>{' '}
        y su derivado,{' '}
        <a
          href="https://github.com/sjaquemate/boludle"
          target="_blank"
          rel="noopener noreferrer"
        >
          Boludle
        </a>
        .<br />
        Las palabras surgen del{' '}
        <a
          href="https://www.diezpalabras.com.ar/"
          target="_blank"
          rel="noopener noreferrer"
        >
          anarchivo del newsletter diez palabras
        </a>
        . Aquí está el{' '}
        <a
          href="https://github.com/lucdima/diezple"
          target="_blank"
          rel="noopener noreferrer"
        >
          código
        </a>
        .<br />
        <br />
      </p>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Palabras:{' '}
        <a
          href="https://twitter.com/marbasch"
          target="_blank"
          rel="noopener noreferrer"
        >
          Marcela Basch
        </a>
        <br />
        Adaptación del código fuente:{' '}
        <a
          href="https://www.lucasdima.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Lucas Dima
        </a>
      </p>
    </BaseModal>
  )
}
